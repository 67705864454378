import React, { useState } from 'react'
import blogsData from '../../data/BlogData';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import { Link } from 'react-router-dom';

const BlogSection = () => {
    const [currBlog, setCurrBlog] = useState({category: 'latest', data:blogsData[0].blogs});

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 6,
        slidesToScroll: 5,
        arrows:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 3,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
            }
          }
        ]
    };

    const settings2 = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 2,
        arrows:true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ]
    };

  return (
    <div className='w-full flex flex-col py-8 px-8 gap-8 max-md:gap-4 max-md:px-6 max-sm:px-4 max-sm:py-4'>
        <div className='w-full flex justify-start px-12 max-md:px-0 '>
            <div className='w-full flex flex-col gap-1'>
                <h2 className='text-2xl max-md:text-lg font-bold text-black'>Inspirations & Blogs</h2>
                <p className='text-base max-md:text-sm max-sm:text-xs font-normal text-gray-500'>Get tips and ideas on latest and interesting wedding trends</p>
            </div>
        </div>
        <section className="blog-section px-8 pr-10 max-md:px-0 max-md:pr-0">
            <Slider {...settings}>
                {
                    blogsData && blogsData.map((blog) => (
                        <div 
                            id={blog.id} 
                            onClick={() => setCurrBlog({category: blog.category, data:blog.blogs})} 
                            className='flex flex-col items-center group'
                        >
                            <div className={`w-[100px] h-[100px] max-sm:w-[70px] max-sm:h-[70px] rounded-full cursor-pointer ${currBlog.category.toLowerCase() === blog.category.toLowerCase() ? 'border-4 border-[#AB1C49]' : 'border-[3px] border-transparent'}`}>
                                <img src={blog.category_img} alt={blog.category} className='w-full h-full rounded-full object-cover mx-auto'/>
                            </div>
                            <span className={`w-[150px] text-lg max-md:text-base max-sm:text-sm font-noremal text-center cursor-pointer ${currBlog.category.toLowerCase() === blog.category.toLowerCase() ? 'text-black' : 'text-gray-400 transition duration-300 ease-in group-hover:underline'}`}>{blog.category}</span>
                        </div>
                    ))
                }
            </Slider>
        </section>
        <section className="blog-card px-8 pr-10 max-sm:px-4">
            <Slider {...settings2}>
            {
                currBlog.data && currBlog.data.map((blog) => (
                    <Link key={blog.id} to={`/${blog.category}/${blog.title}/${blog.id}`} className='w-[350px] flex flex-col bg-white rounded-lg shadow-md border gap-2'>
                        <div className='w-full h-[200px] max-sm:h-[150px] rounded-lg'>
                            <img src={blog.img} alt='blog_img' className='w-full h-full object-cover rounded-lg'/>
                        </div>
                        <div className='w-full flex flex-col p-4 gap-4 max-sm:gap-2 max-sm:p-2'>
                            <p className='text-base max-sm:text-sm font-extrabold text-black'>{blog.title}</p>
                            <div className='w-full flex flex-col'>
                                <span className='text-sm font-semibold text-gray-600 uppercase'>{blog.category}</span>
                                <span className='text-xs font-medium text-gray-400'>{blog.dateAndTime}</span>
                            </div>
                        </div>
                    </Link>
                ))
            }
            </Slider>
        </section>
    </div>
  )
}

export default BlogSection