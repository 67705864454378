import React from 'react'
import { Link } from 'react-router-dom'

const DecorationTab = () => {
  return (
    <div className='w-full flex justify-start items-start gap-8 py-4 px-8 max-md:px-4'>
      <div className='flex flex-col gap-3 max-md:gap-2'>
        <h2 className='text-[#411530] font-semibold text-base'>Weeding Decor</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Mehendi Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Weeding Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Sangeet Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Coctail Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Reception Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Mandap Flower Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Aniversery Flower Decoration</Link>
        </div>
      </div>

      <div className='flex flex-col gap-3 max-md:gap-2'>
        <h2 className='text-[#411530] font-semibold text-base'>Birthday Decor</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Birthday Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Namkaran Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Chhati Ki Raat Decoration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>House Women Party</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Baby Welcome Party</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Birthday Decoration Kids</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Birthday Decoration Adult</Link>
        </div>
      </div>
    </div>
  )
}

export default DecorationTab