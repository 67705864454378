import React from 'react'
import Header from '../components/section/Header'
import Navbar from '../components/section/Navbar'
import Footer from '../components/section/Footer'
import SuperHeading from '../components/common/SuperHeading'
import ContactForm from '../components/common/ContactForm'

const Contact = () => {
  return (
    <div className='w-full h-full flex flex-col items-center'>
        <Header/>
        <Navbar/>
        <SuperHeading title={'Contact Us'}/>

        <ContactForm/>

        <Footer/>
    </div>
  )
}

export default Contact