import React from 'react'

const Login = () => {
  return (
    <div className='w-[500px] h-[400px] bg-white'>
        <div>Log In</div>
    </div>
  )
}

export default Login