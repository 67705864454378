import React from 'react'
import { Link } from 'react-router-dom'

const EventsTab = () => {
  return (
    <div className='w-full flex justify-between items-start py-4 px-8 max-md:px-4 max-md:flex-wrap max-sm:gap-4'>
      <div className='flex flex-col gap-3 max-md:w-[210px]'>
        <h2 className='text-[#411530] font-semibold text-base'>Corporate Event</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Annual day celebration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Monthly Performance Awards</Link>
        </div>
      </div>

      <div className='flex flex-col gap-3  max-md:w-[210px]'>
        <h2 className='text-[#411530] font-semibold text-base'>Coctail Night</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Birthday Party</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Private Party</Link>
        </div>
      </div>

      <div className='flex flex-col gap-3 max-md:w-[210px]'>
        <h2 className='text-[#411530] font-semibold text-base'>Conference Set</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Training</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>General Meetup</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>General Conference</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Press Meet</Link>
        </div>
      </div>
    </div>
  )
}

export default EventsTab