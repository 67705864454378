import bridalLehImg from '../assets/bridal-lehenga.webp';
import outImg from '../assets/outfits.webp';
import blouseDesImg from '../assets/blouse-designs.webp'
import sareeImg from '../assets/sarees.webp';
import mehendiImg from '../assets/mehndi.webp';
import weddingJulleryImg from '../assets/wedding-jewellery.webp';
import makeUpImg from '../assets/mua.webp';
import hairstyleImg from '../assets/hairstyles.webp';

const GalleryData = [
    {
        id:1,
        title:'Bridal Lehenga',
        img: bridalLehImg,
        link:'/'
    },
    {
        id:2,
        title:'Outfits',
        img: outImg,
        link:'/'
    },
    {
        id:3,
        title:'Blouse Designs',
        img: blouseDesImg,
        link:'/'
    },
    {
        id:4,
        title:'Wedding Sarees',
        img: sareeImg,
        link:'/'
    },
    {
        id:5,
        title:'Mehendi Designs',
        img: mehendiImg,
        link:'/'
    },
    {
        id:6,
        title:'Wedding Jullery',
        img: weddingJulleryImg,
        link:'/'
    },
    {
        id:7,
        title:'Wedding MakeUp',
        img: makeUpImg,
        link:'/'
    },
    {
        id:8,
        title:'Wedding HairStyles',
        img: hairstyleImg,
        link:'/'
    }
]

export default GalleryData;