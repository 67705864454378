
const testimonialData = [
    {
        id:1,
        name:'Sumit Rathor',
        designation:'Software Developer',
        from:'Chennai',
        user_img:`https://api.dicebear.com/5.x/initials/svg?seed=SumitRathor`,
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it to make a type specimen book. remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing"
    },
    {
        id:2,
        name:'Harmeet Kaur',
        designation:'Wedding Makeup',
        from:'Indore',
        user_img:`https://api.dicebear.com/5.x/initials/svg?seed=Harmeet Kaur`,
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. when an unknown printer took a galley of type and scrambled it to make a type specimen book. remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing"
    },
    {
        id:3,
        name:'S. Rahul',
        designation:'Photograher',
        from:'Banglore',
        user_img:`https://api.dicebear.com/5.x/initials/svg?seed=S.Rahul`,
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it to make a type specimen book. remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing"
    },
    {
        id:3,
        name:'S. Rahul',
        designation:'Photograher',
        from:'Banglore',
        user_img:`https://api.dicebear.com/5.x/initials/svg?seed=S.Rahul`,
        message:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets."
    }
]

export default testimonialData;