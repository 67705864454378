import CateImg from '../assets/catering.avif';
import MakeUPImg from '../assets/bridal-makeup-artists.avif'
import DecorImg from '../assets/wedding-decorators.avif';
import PhotoGraImg from '../assets/wedding-photographers.avif'
import MehendiImg from '../assets/mehndi-artists.webp'
import DjImg from '../assets/dj.avif'
import WedCardImg from '../assets/wedding-cards.webp'
import wearImg from '../assets/wedding-wear.avif'
import EntertainImg from '../assets/wedding-entertainment.avif'
import giftImg from '../assets/wedding-gifts.avif'
import carImg from '../assets/wedding-cars-rental.avif'
import chroGraImg from '../assets/choreographers.avif'
import PanditImg from '../assets/wedding-pandits.avif'

const categories = [
	{
        page: 1,
        totalPages: 2,
        data:[
            {
                id: 1,
                name: "Makeup Arists",
                image: MakeUPImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 2,
                name: "Decorators",
                image: DecorImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 3,
                name: "Photographers",
                image: PhotoGraImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            },
            {
                id: 4,
                name: "Mehendi Artists",
                image: MehendiImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            },
            {
                id: 5,
                name: "Caterers",
                image: CateImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            },
            {
                id: 6,
                name: "DJs",
                image: DjImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            },
            {
                id: 7,
                name: "Invitations",
                image: WedCardImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            },
            {
                id: 8,
                name: "DJs",
                image: DjImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link:'/'
            }
        ]
    },
    {
        page:2,
        totalPages: 2,
        data:[
            {
                id: 1,
                name: "Weeding wear",
                image: wearImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 2,
                name: "Entertainment",
                image: EntertainImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },

            {
                id: 3,
                name: "Gifts",
                image: giftImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },

            {
                id: 4,
                name: "Cars",
                image: carImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 5,
                name: "Choreograhers",
                image: chroGraImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 6,
                name: "Pandit",
                image: PanditImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
            {
                id: 2,
                name: "Entertainment",
                image: EntertainImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },

            {
                id: 3,
                name: "Gifts",
                image: giftImg,
                image_missing: "https://img.freepik.com/premium-vector/default-image-icon-vector-missing-picture-page-website-design-mobile-app-no-photo-available_87543-11093.jpg",
                price: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s",
                link: '/'
            },
        ]
    }
];

export default categories;