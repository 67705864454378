import React from 'react'
import { Link } from 'react-router-dom'

const DJEventTab = () => {
  return (
    <div className='w-full flex justify-between items-start py-4 px-8 max-md:px-4'>
      <div className='flex flex-col gap-3 max-md:gap-2'>
        <h2 className='text-[#411530] font-semibold text-base'>DJ Systems</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Sound setup</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Karaoke</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Rain Dance</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Stage DJ</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Mobile DJ</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>DJ on Wheels</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Mini DJ</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>DJ Jockey</Link>
        </div>
      </div>

      <div className='flex flex-col gap-3 max-md:gap-2'>
        <h2 className='text-[#411530] font-semibold text-base'>Corporate Event</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Annual day celebration</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Monthly Performance Awards</Link>
        </div>
      </div>
    </div>
  )
}

export default DJEventTab