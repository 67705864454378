import React from 'react'
import { Link } from 'react-router-dom'

const AudioVisiualTab = () => {
  return (
    <div className='w-full flex justify-between items-start py-4 px-8 max-md:px-4'>
      <div className='flex flex-col gap-3'>
        <h2 className='text-[#411530] font-semibold text-base'>Systems</h2>
        <div className='flex flex-col gap-1'>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>PA System</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Projector Screen</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>LED Wall</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Sound Setup</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>Stage Setup</Link>
          <Link className='text-sm font-normal text-gray-500 transition duration-200 ease-in hover:text-[#411530]'>TV or Rental</Link>
        </div>
      </div>
    </div>
  )
}

export default AudioVisiualTab