import rImg from '../assets/couple-img/R.jpeg';
import oipImg from '../assets/couple-img/OIP.jpeg';
import oip1Img from '../assets/couple-img/OIP (1).jpeg';
import oip2Img from '../assets/couple-img/OIP (2).jpeg';
import oip3Img from '../assets/couple-img/OIP (3).jpeg';
import oip4Img from '../assets/couple-img/OIP (4).jpeg';
import oip5Img from '../assets/couple-img/OIP (5).jpeg';
import coupleImg from '../assets/couple-img/couple-2.jpg'
import couple2Img from '../assets/couple-img/couple-2-2.jpeg';
import couple3Img from '../assets/couple-img/couple-2-3.jpeg';
import couple4Img from '../assets/couple-img/couple-2-4.jpeg';
import cpImg from '../assets/couple-img/cp.jpg';


const realWeddingData = [
    {
        id:1,
        name:'Ankita and Advit',
        location:'Mumbai Central Mumbai',
        total_images:70,
        images:[ // we need to add min 4 photos
            {
                img_id:'img1',
                img: rImg
            },
            {
                img_id:'img2',
                img: oipImg
            },
            {
                img_id:'img3',
                img: oip1Img
            },
            {
                img_id:'img4',
                img: oip2Img
            },
            {
                img_id:'img5',
                img: oip3Img
            },
            {
                img_id:'img6',
                img: oip4Img
            },
            {
                img_id:'img7',
                img: oip5Img
            },
        ]
    },
    {
        id:2,
        name:'Suminder & Deepika',
        location:'Mumbai Central Mumbai',
        total_images:78,
        images:[
            {
                img_id:'img4',
                img: coupleImg
            },
            {
                img_id:'img5',
                img: oip4Img
            },
            {
                img_id:'img6',
                img: oip2Img
            },
            {
                img_id:'img7',
                img: oip5Img
            },
        ]
    },
    {
        id:3,
        name:'Karan & Kamini',
        location:'Dwarka, New Delhi',
        total_images:54,
        images:[
            {
                img_id:'img4',
                img: cpImg
            },
            {
                img_id:'img5',
                img: couple2Img
            },
            {
                img_id:'img6',
                img: couple3Img
            },
            {
                img_id:'img7',
                img: couple4Img
            },
        ]
    }
]

export default realWeddingData;