import letestImg from '../assets/blogs/letest.jpg';
import wedPlanImg from '../assets/blogs/wedding-planning.jpg'
import photoImg from '../assets/blogs/photos.jpg';
import wedDayImg from '../assets/blogs/110-mob-min.jpg'
import wedPhotoGrapher from '../assets/blogs/wedding-photographers.jpg';
import wedFavGiftImg from '../assets/blogs/85-mob-min.jpg';
import sangeetImg from '../assets/blogs/111-mob-min.jpg';
import engagementImg from '../assets/blogs/109-mob-min.jpg';
import Blog1Img from '../assets/blogs/blog1.avif';
import Blog2Img from '../assets/blogs/blog2.avif';
import Blog3Img from '../assets/blogs/blog3.avif';
import Blog4Img from '../assets/blogs/blog4.avif';
import Blog5Img from '../assets/blogs/blog5.avif';
//wedding planning
import wedPlanImg1 from '../assets/blogs/wedplan1.jpg';
import wedPlanImg2 from '../assets/blogs/wedplan2.jpeg';
import wedPlanImg3 from '../assets/blogs/wedplan3.jpeg';
import wedPlanImg4 from '../assets/blogs/wedplan4.webp';
import wedPlanImg5 from '../assets/blogs/wedplan5.webp';

// haldi image
import haldi_img from '../assets/blogs/haldi_img.jpg'
import haldi_img1 from '../assets/couple-img/haldi_img1.jpeg';
import haldi_img2 from '../assets/couple-img/haldi_img2.webp';
import haldi_img3 from '../assets/couple-img/haldi_img3.jpeg';
import haldi_img4 from '../assets/couple-img/haldi_img4.jpg';
// mehendi images
import mehendi_img from '../assets/blogs/mehendi_img.jpg';
import mehendi_img1 from '../assets/blogs/mehendi_img1.jpeg';
import mehendi_img2 from '../assets/blogs/mehendi_img2.jpeg';
import mehendi_img3 from '../assets/blogs/mehendi_img3.jpeg';
import mehendi_img4 from '../assets/blogs/mehendi_img4.jpeg';
// decoration idea images
import decorationImg from '../assets/blogs/decoration-img.jpeg';
import decorationImg1 from '../assets/blogs/decoration-img1.jpeg';
import decorationImg2 from '../assets/blogs/decoration-img2.jpeg';
import decorationImg3 from '../assets/blogs/decoration-img3.jpeg';
import decorationImg4 from '../assets/blogs/decoration-img4.jpg';
// wedding day images
import weddingDay_Img from '../assets/blogs/weddingDay_Img.webp'
import weddingDay_Img1 from '../assets/blogs/weddingDay_Img1.webp'
import weddingDay_Img2 from '../assets/blogs/weddingDay_Img2.jpeg'
import weddingDay_Img3 from '../assets/blogs/weddingDay_Img3.jpg'
import weddingDay_Img4 from '../assets/blogs/weddingDay_Img4.jpg'
// photographers
import photoGrapherImg from '../assets/blogs/photographer_img.webp';
import photoGrapherImg1 from '../assets/blogs/photographer_img1.jpeg';
import photoGrapherImg2 from '../assets/blogs/photographer_img2.png';
import photoGrapherImg3 from '../assets/blogs/photographer_img3.jpeg';
import photoGrapherImg4 from '../assets/blogs/photographer_img4.jpeg';

const blogsData = [
    {
        id:1,
        category:'Latest',
        category_img: letestImg,
        blogs:[
            {
                id:'blog_1',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Bridal Fashion',
                img:Blog1Img,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_2',
                title:'Real Brides in Dreamy Tulle Outfits with Modern Flair',
                category:'Wedding Planning',
                img:Blog2Img,
                dateAndTime:'01 Jan 2024'
            },
            {
                id:'blog_3',
                title:"Brides in Side Hairstyles: A Timeless Trend You'll Love",
                category:'Bridal Fashion',
                img:Blog3Img,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_4',
                title:'Top 21 Unique Groom Shawls and Stoles to Complete Your Wedding Outfit',
                category:'Luxury',
                img:Blog4Img,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_5',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Celebrity',
                img:Blog5Img,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:3,
        category:'Wedding Planning',
        category_img: wedPlanImg,
        blogs:[
            {
                id:'blog_1',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Wedding Planning',
                img:wedPlanImg1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_2',
                title:'Real Brides in Dreamy Tulle Outfits with Modern Flair',
                category:'Wedding Planning',
                img:wedPlanImg2,
                dateAndTime:'01 Jan 2024'
            },
            {
                id:'blog_3',
                title:"Brides in Side Hairstyles: A Timeless Trend You'll Love",
                category:'Wedding Planning',
                img:wedPlanImg3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_4',
                title:'Top 21 Unique Groom Shawls and Stoles to Complete Your Wedding Outfit',
                category:'Wedding Planning',
                img:wedPlanImg4,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_5',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Planning',
                img:wedPlanImg5,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:4,
        category:'Photo & Video Ideas',
        category_img: photoImg,
        blogs:[
            {
                id:'blog_1',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Photo & Video Ideas',
                img:wedPlanImg4,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_2',
                title:'Real Brides in Dreamy Tulle Outfits with Modern Flair',
                category:'Photo & Video Ideas',
                img:wedPlanImg5,
                dateAndTime:'01 Jan 2024'
            },
            {
                id:'blog_3',
                title:"Brides in Side Hairstyles: A Timeless Trend You'll Love",
                category:'Photo & Video Ideas',
                img:wedPlanImg1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_4',
                title:'Top 21 Unique Groom Shawls and Stoles to Complete Your Wedding Outfit',
                category:'Photo & Video Ideas',
                img:wedPlanImg3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_5',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Photo & Video Ideas',
                img:wedPlanImg2,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:6,
        category:'Decorating Ideas',
        category_img: decorationImg,
        blogs:[
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Photo & Video Ideas',
                img: decorationImg1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Photo & Video Ideas',
                img: decorationImg2,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Photo & Video Ideas',
                img: decorationImg4,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Photo & Video Ideas',
                img: decorationImg3,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:7,
        category:'Wedding Day',
        category_img: weddingDay_Img,
        blogs:[
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Day',
                img: weddingDay_Img1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Day',
                img: weddingDay_Img2,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Day',
                img: weddingDay_Img3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Day',
                img: weddingDay_Img4,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:9,
        category:'Wedding Photographers',
        category_img: photoGrapherImg,
        blogs:[
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Photographers',
                img: photoGrapherImg1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Photographers',
                img: photoGrapherImg2,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Photographers',
                img: photoGrapherImg3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_decorating1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Wedding Photographers',
                img: photoGrapherImg4,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:10,
        category:'Mehndi Decoration',
        category_img: mehendi_img,
        blogs:[
            {
                id:'blog_Menhendi1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Mehndi Decoration',
                img:mehendi_img1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_Menhendi1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Mehndi Decoration',
                img:mehendi_img2,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_Menhendi1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Mehndi Decoration',
                img:mehendi_img3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_Menhendi1',
                title:'Heartwarming Namaste Shots That Are Too Adorable!',
                category:'Mehndi Decoration',
                img:mehendi_img4,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:11,
        category:'Haldi Decoration',
        category_img: haldi_img,
        blogs:[
            {
                id:'blog_1',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Haldi Decoration',
                img:haldi_img1,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_2',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Haldi Decoration',
                img:haldi_img2,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_3',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Haldi Decoration',
                img:haldi_img3,
                dateAndTime:'27 Oct 2024'
            },
            {
                id:'blog_3',
                title:'Astro-Bliss: Find the Proposal Ring That Matches Her Zodiac Personality',
                category:'Haldi Decoration',
                img:haldi_img4,
                dateAndTime:'27 Oct 2024'
            },
        ]
    },
    {
        id:12,
        category:'Wedding Favours & Gifts',
        category_img: wedFavGiftImg,
        title:'',
        dateAndTime:'01 Sep 2024'
    },
    {
        id:13,
        category:'Sangeet',
        category_img: sangeetImg,
        title:'',
        dateAndTime:'01 Sep 2024'
    },
    {
        id:14,
        category:'Engagement',
        category_img: engagementImg,
        title:'',
        dateAndTime:'01 Sep 2024'
    },
]

export default blogsData;