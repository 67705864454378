import React, { useState } from 'react'
import Header from '../components/section/Header';
import Navbar from '../components/section/Navbar';
import BannerImg from '../assets/home-banner.jpg';
import { FaChevronLeft } from "react-icons/fa";
import { FaChevronRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import categoriesData from '../data/Categories';
import PopularVenue from '../components/common/PopularVenue';
import Gallery from '../components/common/Gallery';
import WeddingCategory from '../components/home-com/WeddingCategory';
import RealWeddings from '../components/home-com/RealWeddings';
import BasicContactForm from '../components/common/BasicContactForm';
import BlogSection from '../components/home-com/BlogSection';
import TestimonialSection from '../components/home-com/TestimonialSection';
import Footer from '../components/section/Footer';
import WhyVideek from '../components/common/WhyVideek';

// #411530
// #D1512D
// #F5C7A9
// #AB1C49

const Home = () => {
  const [currPage, setCurrPage] = useState(0);

  const prevBtnHandler = () => {
    if(currPage === 0){
      return;
    }
    else{
      setCurrPage(currPage - 1);
    }
  }

  const nextBtnHandler = () => {
    if((currPage + 1) === categoriesData[currPage].totalPages){
      return;
    }
    else{
      setCurrPage(currPage + 1);
    }
  }

  return (
    <div className='w-full h-full flex flex-col items-center'>
        <Header/>
        <Navbar/>

        
        <div className='w-full h-[90vh] relative'>
          <img src={BannerImg} alt='home-banner' className='w-full h-full object-cover'/>

          <div className='absolute top-0 left-0 w-full h-full home-banner flex justify-center items-center'>
            <div className='flex flex-col items-center gap-4 mt-16'>
              <h2 className='text-4xl text-white font-bold max-md:text-xl'>Plan With a DJ Event</h2>
              <button className='w-[150px] py-2 bg-[#AB1C49] text-zinc-100 rounded-full text-base max-sm:text-sm font-semibold transition duration-300 ease-in hover:bg-[#88173b]'>Explore Here</button>
            </div>
          </div>
        </div>

        {/* vendor categories */}
        <div className='w-full flex flex-col p-8 max-md:p-6 max-sm:p-4 gap-8 bg-white pb-10'>
          <div className='w-full flex justify-between items-center'>
            <h3 className='text-black font-bold text-2xl max-sm:text-lg'>Vendor categories</h3>
            <div className='flex justify-center gap-4 items-center'>
              <div onClick={prevBtnHandler} className='w-[40px] h-[40px] max-sm:w-[25px] max-sm:h-[25px] flex justify-center items-center bg-white shadow-md shadow-[#00000086] rounded-full cursor-pointer transition duration-200 ease-in hover:shadow-[#0000001d]'><FaChevronLeft className='text-xl max-sm:text-base'/></div>
              <div onClick={nextBtnHandler} className='w-[40px] h-[40px] max-sm:w-[25px] max-sm:h-[25px] flex justify-center items-center bg-white shadow-md shadow-[#00000086] rounded-full cursor-pointer transition duration-200 ease-in hover:shadow-[#0000001d]'><FaChevronRight className='text-xl max-sm:text-base'/></div>
            </div>
          </div>

          <div className='w-full flex justify-between items-start gap-8 flex-wrap transition duration-300'>
            {
              categoriesData[currPage].data && categoriesData[currPage].data.map((item) => (
                <Link to={item.link} key={item.id} className='w-[270px] max-sm:w-full flex flex-col p-4 rounded card-shadow gap-4'>
                  <img src={item.image} alt={item.image_missing} className='w-full h-[180px] object-cover rounded'/>
                  <div className='flex flex-col'>
                    <div className='text-base font-semibold text-black'>{item.name}</div>
                    <div className='flex justify-start gap-1 items-center'>
                      <div className='text-sm font-medium text-gray-400'>{item.price}</div>
                    </div>
                  </div>
                </Link>
              ))
            }
          </div>
        </div>

        {/* Why Videek event  */}
        <WhyVideek/>

        <PopularVenue/>
        <Gallery/>
        <WeddingCategory/>
        <RealWeddings/>
        <BasicContactForm/>
        <BlogSection/>
        <TestimonialSection/>
        <Footer/>
    </div>
  )
}

export default Home