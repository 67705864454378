import React from "react";
import GalleryData from '../../data/Gallery';
import Slider from "react-slick";
import { Link } from "react-router-dom";


const Gallery = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows:true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  return (
    <div className='w-full flex flex-col p-8 max-md:p-6 max-sm:p-4 gap-8 bg-white'>
      <div className='w-full flex justify-center items-center'>
        <h2 className=' text-2xl max-sm:text-lg font-bold'>Gallery to Look for</h2>
      </div>
      <section className="gallery-section px-8 pr-10 max-sm:px-6">
        <Slider {...settings} className="w-full">
            {
                GalleryData && GalleryData.map((item) => (
                    <Link 
                        key={item.id} 
                        to={item.link}
                        className='w-[250px] p-4 flex flex-col shadow rounded gap-4 group'
                        // Add gap between slider items
                        >
                        <div className="w-full h-[260px] max-sm:h-[240px] rounded overflow-hidden">
                            <img 
                                src={item.img} 
                                alt={item.title} 
                                className='w-full h-full object-cover rounded transition duration-300 ease-in-out group-hover:transition-transform group-hover:scale-105' 
                            />
                        </div>
                        <div className='w-full flex justify-center items-center mt-2 text-base font-semibold text-gray-500 group-hover:text-[#f03b9e]'>
                            {item.title}
                        </div>
                    </Link>
                ))
            }
        </Slider>
      </section>
    </div>
  );
}

export default Gallery;
